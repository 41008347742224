import type { TourStepProps } from 'antd'
import { createContext } from 'react'

export interface TourContextType {
  isTourMode: boolean
  next: () => void
  currentConfig: TourStepConfig
}

export type ExtraPlacement = 'top' | 'left' | 'right' | 'bottom'

export const TourContext = createContext<TourContextType | null>(null)

export type TourStepConfig = TourStepProps & {
  beforeNext?: () => Promise<void>
  descriptionPlacement?: ExtraPlacement
}

export const tourStepsMap = new Map<string, TourStepConfig[]>()
export function registerStep(tourName: string, step: number, config: TourStepConfig) {
  const steps = tourStepsMap.get(tourName) ?? []

  steps.length = Math.max(steps.length, step + 1)

  steps.splice(step, 1, config)

  tourStepsMap.set(tourName, steps)
}
