import UnlockBg from '@/assets/images/unlock-bg.png'
import type { FunctionName } from '@/store/lock.store'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { startCase } from 'lodash'
import { createElement, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { getIconRef, getIconType } from './lockable-menu'

const duration = 600
export default function showUnlockModal(name: FunctionName): Promise<void> {
  return new Promise((resolve) => {
    const modal = Modal.info({
      content: (
        <UnlockModalContent
          name={name}
          onClose={() => {
            modal.destroy()
            resolve()
          }}
          onAnimateStart={() => {
            modal.update({
              styles: {
                content: {
                  visibility: 'hidden',
                },
                mask: {
                  visibility: 'hidden',
                },
              },
            })
          }}
        />
      ),

      styles: {
        content: {
          background: `url(${UnlockBg}) no-repeat center/360px 200px`,
          height: 200,
          boxShadow: 'none',
        },
        mask: {
          background: `radial-gradient(50% 50% at 50% 50%, rgba(6, 24, 81, 0.72) 0%, rgba(0, 9, 37, 0.72) 100%)`,
        },
      },
      className: '[&_.ant-modal-close]:(-top-6 right-0)',
      width: 360,
      footer: null,
      centered: true,
      icon: null,
    })
  })
}

// eslint-disable-next-line react-refresh/only-export-components
function UnlockModalContent(props: { name: FunctionName; onClose?: () => void; onAnimateStart?: () => void }) {
  const { name } = props

  const ref = useRef(null)
  const [inProp, setInProp] = useState(true)
  const iconType = getIconType(name)
  useEffect(() => {
    setTimeout(() => {
      props.onAnimateStart?.()
      setInProp(false)
    }, 2000)
  }, [])

  const targetPosition = getIconRef(name)?.current?.getBoundingClientRect()
  const transitionStyles = {
    exiting: {
      transform: `translate(${targetPosition?.left - ref.current?.getBoundingClientRect().left}px, ${targetPosition?.top - ref.current?.getBoundingClientRect().top - 16}px)`,
      visibility: 'visible',
    },
    exited: {
      opacity: 0,
    },
  }

  return (
    <div className="relative flex flex-col gap-6 py-6">
      <Button
        className="absolute -right-8 -top-12"
        type="link"
        onClick={() => {
          setInProp(false)
          props.onAnimateStart?.()
        }}
      >
        <CloseOutlined className=" text-white" />
      </Button>
      <h2
        style={{
          background: `linear-gradient(90deg, rgba(38, 80, 204, 0) 0%, rgba(38, 80, 204, 0.4) 50.5%, rgba(38, 80, 204, 0) 100%)`,
        }}
        className="flex h-10 items-center justify-center"
      >
        <div
          style={{
            backgroundImage: `linear-gradient(90deg, #FFD12E 0%, #FFF4CE 39.59%, #FFF4CE 70.42%, #FFD12E 100%)`,
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
          }}
          className="text-2xl font-bold"
        >
          Congratulations!
        </div>
      </h2>
      <div className="font-600 flex items-center justify-center gap-2 text-base text-white">
        <Transition
          timeout={duration}
          nodeRef={ref}
          in={inProp}
          onExited={() => {
            props.onClose?.()
          }}
        >
          {(state) =>
            iconType &&
            createElement(iconType, {
              ref,
              style: {
                ...transitionStyles[state],
                transition: `transform ${duration}ms ease-in-out`,
              },
            })
          }
        </Transition>
        <span>{startCase(name?.toLowerCase())} Unlock</span>
      </div>
    </div>
  )
}
