import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg width={size} height={(size * 25) / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.29912 7.49822C3.40769 6.52029 4.34486 5.74983 5.51848 5.66554C10.3428 5.31905 13.6924 5.31804 18.4781 5.66341C19.6574 5.74852 20.5958 6.52672 20.7002 7.50983C21.1214 11.4778 21.0825 14.2795 20.6853 18.2807C20.5866 19.2747 19.6418 20.0665 18.4497 20.1507C13.65 20.4899 10.3044 20.4891 5.56588 20.1524C4.37088 20.0675 3.42612 19.2715 3.3285 18.2749C2.94056 14.3141 2.85423 11.5055 3.29912 7.49822Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.55761 8.49959C5.78153 8.25532 6.16107 8.23882 6.40534 8.46273L10.716 12.4142C11.4424 13.0801 12.5574 13.0801 13.2838 12.4142L17.5945 8.46273C17.8387 8.23882 18.2183 8.25532 18.4422 8.49959C18.6661 8.74386 18.6496 9.1234 18.4053 9.34732L14.0947 13.2988C12.9095 14.3852 11.0904 14.3852 9.90516 13.2988L5.59447 9.34732C5.3502 9.1234 5.3337 8.74386 5.55761 8.49959Z"
        fill={color}
      />
    </svg>
  )
}
