import { TourType } from '@/api/tour.api'
import { quitTour, tourStore } from '@/stores/tour.store'

import { LogOut } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

export default function TourBar() {
  const { isTourMode, activeName } = useSnapshot(tourStore)
  const navigate = useNavigate()
  if (!isTourMode) return null
  return (
    <div className="bg-#009E8CED z-2000 flex h-8 flex-none items-center justify-between gap-8 px-8">
      <div />
      <div className="flex items-center gap-2 text-sm text-white">
        <span className="font-semibold">New User Guide</span>
        <span>-</span>
        <div className="first-letter:uppercase">{activeName === TourType.Hunting ? 'Bounty Hunting' : activeName}</div>
      </div>
      <div>
        <LogOut
          className="cursor-pointer text-white"
          size={14}
          onClick={() => {
            quitTour()
            navigate('/app')
          }}
        />
      </div>
    </div>
  )
}
