import { ThemeConfig, theme } from 'antd'

const AntdTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: '#875DFF',
    // colorError: '#D92B2B',
    boxShadow: 'none',
    colorSuccess: '#00C165',
    colorInfo: '#0A77FF',
    colorLink: '#875DFF',
    colorBgBase: '#1C1C26',
    borderRadius: 8,
    colorBorder: '#404049',
    colorLinkHover: '#ffffff80',
  },
  components: {
    Drawer: {
      colorBgElevated: '#1C1C26',
    },
    Button: {
      primaryShadow: 'none',
      fontSizeLG: 14,
      boxShadow: 'none',
    },
    Table: {
      stickyScrollBarBg: 'initial',
    },
    Message: {
      boxShadow: '0px 10px 6px 0px #00000010',
    },
    Menu: {
      iconSize: 16,
      itemSelectedBg: '#30004010',
      iconMarginInlineEnd: 12,
      itemActiveBg: '#30004010',
      itemMarginBlock: 1,
      itemColor: '#020008E0',
      subMenuItemBg: 'transparent',
    },
    Empty: {
      colorTextDescription: '#0000003D',
    },
    DatePicker: {
      lineWidth: 0,
      activeBorderColor: 'none',
      cellActiveWithRangeBg: 'rgba(0,0,0,0.08)',
      activeShadow: '0 0 0 1px rgba(0,0,0,0.8)',
    },
    Select: {
      colorBgContainer: 'transparent',
      optionSelectedBg: 'rgba(0,0,0,0.08)',
      optionActiveBg: 'rgba(0,0,0,0.08)',
      boxShadow: 'none',
      controlOutlineWidth: 0,
      fontSizeLG: 14,
    },
    Input: {
      fontSizeLG: 14,
      colorBgContainer: 'transparent',
    },
    Rate: {
      marginXS: 1,
    },
    Cascader: {
      colorBgContainer: '#1C1C26',
      colorPrimary: '#875DFF',
      optionSelectedBg: 'rgba(0,0,0,0.08)',
      colorText: '#000',
    },
    Result: {
      titleFontSize: 16,
      subtitleFontSize: 14,
      lineHeight: 1,
      colorSuccess: '#fff',
      marginXS: 12,
    },
    Anchor: {},
    Switch: {},
    Modal: {
      contentBg: '#252532',
      borderRadius: 24,
      borderRadiusLG: 24,
    },
    Timeline: {
      dotBg: 'transparent',
    },
    Steps: {
      colorSplit: '#ffffff0c',
    },
  },
}

export default AntdTheme
