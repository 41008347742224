import { proxy, snapshot, subscribe } from 'valtio'

export enum DynamicView {
  EMAIL_VIEW = 'EMAIL_VIEW',
  WALLET_VIEW = 'WALLET_VIEW',
  NORMAL_VIEW = 'NORMAL_VIEW',
}

export interface DynamicStore {
  view: DynamicView
}

export const dynamicStore: DynamicStore = proxy({
  view: DynamicView.NORMAL_VIEW,
})

export function updateDynamicView(view: DynamicView) {
  dynamicStore.view = view
}
