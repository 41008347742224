import { TourStep, next, setStepConfig, tourStore } from '@/stores/tour.store'
import React, { useEffect, useRef } from 'react'
import { subscribeKey } from 'valtio/utils'

export default function withTour(
  Component: React.FC,
  configs: ({
    name: string
    step: number
    targetIndex?: number
    title: string
    preventClick?: boolean
  } & Partial<TourStep>)[],
  trigger: 'click' | null = null,
) {
  const targets = new Set<{ current: HTMLElement }>()
  setConfigs()
  subscribeKey(tourStore, 'activeName', setConfigs)
  return (props) => {
    const ref = useRef(null)

    useEffect(() => {
      targets.add(ref)
      return () => {
        targets.delete(ref)
      }
    }, [])

    const activeConfig = configs.find(({ name, step }) => name === tourStore.activeName && step === tourStore.current)

    return (
      <Component
        {...props}
        onClick={() => {
          if (!activeConfig?.preventClick) {
            props.onClick?.()
          }
          if (trigger === 'click') next()
        }}
        ref={ref}
      />
    )
  }

  function setConfigs() {
    configs
      .filter(({ name }) => name === tourStore.activeName)
      .forEach(({ step, targetIndex, name: _, ...rest }) => {
        setStepConfig(step, { ...rest, target: () => [...targets.values()][targetIndex ?? 0]?.current })
      })
  }
}
