import { useUserStore } from '@/store/user.store'
import { Tooltip } from 'antd'
import { LogOut } from 'lucide-react'
import { Link } from 'react-router-dom'
import { logout } from '@/store/auth.store'
import { useMemo } from 'react'
import { ShortenAddress } from '@/utils/wallet-address'
import { toUserFriendlyAddress } from '@tonconnect/sdk'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { TonConnectUI, useTonConnectUI } from '@tonconnect/ui-react'

export default function AppUser() {
  const { info, username } = useUserStore()
  const { handleLogOut } = useDynamicContext()
  const [tonConnectUI] = useTonConnectUI()

  const handleLogout: React.MouseEventHandler = (e) => {
    e.preventDefault()
    handleLogOut()
    tonConnectUI.disconnect()
    logout()
  }

  return (
    <div className="mt-2 pl-4">
      <Link
        className="rounded-3 flex items-center justify-center gap-2 px-4 py-3 hover:text-white"
        to="/app/settings/account"
      >
        {info?.avatar_url && <img src={info?.avatar_url} className="h-6 w-6 rounded-full " />}
        <div className="hidden flex-1 truncate lg:block">{username}</div>
        <Tooltip title="Log out">
          <div className="py-2 pl-2" onClick={handleLogout}>
            <LogOut size={16} className="hidden lg:block"></LogOut>
          </div>
        </Tooltip>
      </Link>
    </div>
  )
}
