import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg width={size} height={(size * 25) / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.9408 9.95037L19.1026 4.00842C20.2507 3.55849 21.3465 4.65433 20.8966 5.80241L14.9547 20.9642C14.5449 22.0098 13.1615 22.2392 12.4996 21.3714L10.2972 18.4836C9.64663 17.6306 9.68102 16.4223 10.3804 15.5607L14.7183 10.3172C14.7908 10.2279 14.6771 10.1142 14.5878 10.1867L9.34432 14.5247C8.4827 15.224 7.27444 15.2584 6.42145 14.6078L3.53365 12.4054C2.66581 11.7435 2.89524 10.3601 3.9408 9.95037Z"
        stroke={color}
        strokeWidth="1.2"
      />
    </svg>
  )
}
