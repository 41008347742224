import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={(size * 25) / 24}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.30502C12.3313 7.30502 12.6 7.57365 12.6 7.90502V8.21411H14.4996L14.4999 8.81411L14.4998 9.41411H12.6V12.305L12.9091 12.305C14.3701 12.305 15.5545 13.4894 15.5545 14.9505C15.5545 16.4116 14.3701 17.596 12.9091 17.596H12.6V17.905C12.6 18.2364 12.3313 18.505 12 18.505C11.6686 18.505 11.4 18.2364 11.4 17.905V17.596H9.50019L9.49996 16.996L9.49998 16.396H11.4V13.505L11.0908 13.505C9.62972 13.505 8.44531 12.3206 8.44531 10.8596C8.44531 9.39851 9.62972 8.21411 11.0908 8.21411H11.4V7.90502C11.4 7.57365 11.6686 7.30502 12 7.30502ZM11.4 9.41411H11.0908C10.2925 9.41411 9.64531 10.0613 9.64531 10.8596C9.64531 11.6579 10.2925 12.305 11.0908 12.305L11.4 12.305V9.41411ZM12.6 13.505V16.396H12.9091C13.7074 16.396 14.3545 15.7488 14.3545 14.9505C14.3545 14.1522 13.7074 13.505 12.9091 13.505L12.6 13.505ZM9.49996 16.996L9.49998 16.396C9.16861 16.396 8.89996 16.6646 8.89996 16.996C8.89996 17.3273 9.16882 17.596 9.50019 17.596L9.49996 16.996ZM14.4999 8.81411L14.4998 9.41411C14.8312 9.41411 15.0999 9.14548 15.0999 8.81411C15.0999 8.48274 14.831 8.21411 14.4996 8.21411L14.4999 8.81411Z"
        fill={color}
      />
      <circle cx="12" cy="12.905" r="9" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  )
}
