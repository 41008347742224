import { logout } from '@/store/auth.store'
import { notificationStoreActions } from '@/stores/notification.store'
import { AxiosHeaders, type AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'
import CryptoJS from 'crypto-js'

export const md5Interceptor = (config: InternalAxiosRequestConfig<any>) => {
  // 对请求进行md5加密
  const Timestamp = new Date().getTime()
  const salt = 'woshinibaba^***@113'
  const requestUrl = config.baseURL + config.url
  const Signature = CryptoJS.MD5(requestUrl + Timestamp + salt).toString()
  if (!config.headers) config.headers = new AxiosHeaders()
  config.headers['Signature'] = Signature
  config.headers['Timestamp'] = Timestamp
  return config
}

export const requestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  // 放在这里是表示，请求会自动带上，更新localstorage也会立即生效，不用update header
  if (!config.headers) config.headers = new AxiosHeaders()
  config.headers['token'] = localStorage.getItem('token')
  config.headers['uid'] = localStorage.getItem('uid')
  return config
}

export const taskInterceptor = (res: AxiosResponse<{ task_finish_notice?: TaskNotice[] }>) => {
  const tasks = res.data.task_finish_notice
  if (tasks?.length) {
    notificationStoreActions.getUnread()
  }
  return res
}

export const baseResponseInterceptor = (res: AxiosResponse): any => {
  if (res.data.success) {
    return res.data
  } else {
    if (res.data.errorCode === 1003) {
      logout()
    }
    return Promise.reject(new Error(res.data?.errorMessage))
  }
}

interface TaskNotice {
  task_config_id: string
  task_id: string
  task_name: string
  biz_type: string
  category: string
  reward: {
    reward_icon: string
    reward_value: number
    reward_type: string
  }[]
}

export async function checkCloudflareValidation(error: AxiosError) {
  const headers = error.response?.headers

  if (
    error.response?.status === 403 &&
    /cloudflare/i.test((headers?.server as string) || '') &&
    /challenge/i.test(headers?.['cf-mitigated'] || '')
  ) {
    return location.reload()
  }

  return Promise.reject(error)
}

export function headerTokenInterceptor(config: InternalAxiosRequestConfig<any>) {
  if (!config.headers) config.headers = new AxiosHeaders()
  config.headers['Token'] = localStorage.getItem('token')
  config.headers['UID'] = localStorage.getItem('uid')
  return config
}
