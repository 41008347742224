import { isLocked, type FunctionName } from '@/store/lock.store'
// import { LockFilled } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import type { MenuItemType } from 'antd/lib/menu/interface'
import { LockKeyholeIcon } from 'lucide-react'
import { useEffect, useRef, type ForwardRefExoticComponent, type RefObject } from 'react'

export default function lockableMenu(
  key: FunctionName,
  { lockTip, ...menu }: Omit<MenuItemType, 'icon'> & { icon?: any; lockTip?: string },
  parentIcon?: ForwardRefExoticComponent<any>,
): MenuItemType {
  iconMap.set(key, menu.icon || parentIcon)

  if (!isLocked(key)) {
    return {
      ...menu,
      icon: <MenuIcon type={menu.icon} name={key} />,
      label: <div>{menu.label}</div>,
    }
  }

  return {
    ...menu,
    disabled: true,
    icon: <MenuIcon className="text-gray-400" type={menu.icon} name={key} />,
    label: (
      <Tooltip title={lockTip} placement="right">
        <Flex align="center" justify="space-between" className="text-gray-400">
          <div>{menu.label}</div>
          <LockKeyholeIcon size={20} />
        </Flex>
      </Tooltip>
    ),
  }
}

const iconRefMap = new Map<FunctionName, RefObject<HTMLElement>>()
const iconMap = new Map<FunctionName, ForwardRefExoticComponent<any>>()

export const getIconRef = (name: FunctionName) => iconRefMap.get(name)
export const getIconType = (name: FunctionName) => iconMap.get(name)

function MenuIcon(props: { name: FunctionName; type?: ForwardRefExoticComponent<any>; className?: string }) {
  const ref = useRef(null) as any
  useEffect(() => {
    iconRefMap.set(props.name, ref)
  }, [])
  if (!props.type) return <div ref={ref} />
  return <props.type ref={ref} className={props.className} />
}
