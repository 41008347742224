import { isLocked, FunctionName } from '@/store/lock.store'
import { notificationStoreActions } from '@/stores/notification.store'
import { message } from 'antd'
import { startCase } from 'lodash'
import { redirect } from 'react-router-dom'

export const lockLoader = (key: FunctionName) => async () => {
  if (!isLocked(key)) return null
  await notificationStoreActions.getUnread()
  if (isLocked(key)) {
    console.log(key)
    const info =
      key === FunctionName.BountyHunting
        ? 'Unlock bounty hunting first to participate in this campaign.'
        : `${startCase(key.toLowerCase())} is locked! Try unlock first!`

    message.info(info)
    return redirect('/app')
  }
  return null
}
