import { TourStepProps } from 'antd'
import { proxy } from 'valtio'
import { subscribeKey } from 'valtio/utils'

export enum TourType {
  Submission = 'submission',
  Validation = 'validation',
  Hunting = 'bounty_hunting',
  Reputation = 'reputation',
}

export type TourStoreType = {
  isTourMode: boolean
  activeName: TourType | null
  current: number
  steps: TourStep[]
}

export type TourStep = TourStepProps & {
  showNextButton?: boolean
  target: () => HTMLElement
  disabledInteraction?: boolean
}

export const tourStore = proxy<TourStoreType>({
  activeName: null,
  current: 0,
  isTourMode: false,
  steps: [],
})

export function startTour(name: TourType, step = 0) {
  tourStore.activeName = name
  jump(step)
}

subscribeKey(tourStore, 'isTourMode', (v) => {
  if (!v) {
    quitTour()
  }
})

export function jump(step: number) {
  setTimeout(() => {
    tourStore.current = step
  }, 600)
}

export function next() {
  if (tourStore.isTourMode) jump(tourStore.current + 1)
}

export function quitTour() {
  tourStore.activeName = null
  tourStore.current = 0
  tourStore.steps = []
}

export function setStepConfig(step: number, config: TourStep) {
  tourStore.steps[step] = config
  tourStore.steps = [...tourStore.steps]
}
