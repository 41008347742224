import { VITE_DYNAMIC_ENVIRONMENT_ID } from '@/configs/config'
import { DynamicView, dynamicStore } from '@/store/dynamic.store'
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { SdkViewSectionType, SdkViewType } from '@dynamic-labs/sdk-api'
import { DynamicContextProvider, mergeNetworks } from '@dynamic-labs/sdk-react-core'
import { ReactNode, useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'

const EMAIL_VIEW = {
  type: SdkViewType.Login,
  sections: [
    {
      type: SdkViewSectionType.Email,
    },
  ],
}

const WALLET_VIEW = {
  type: SdkViewType.Login,
  sections: [
    {
      type: SdkViewSectionType.Wallet,
    },
  ],
}

export default function DynamicContextResolver(props: { children: ReactNode }) {
  const [viewOverrides, setViewOverrides] = useState([])
  const { view } = useSnapshot(dynamicStore)

  useEffect(() => {
    if (view === DynamicView.EMAIL_VIEW) {
      setViewOverrides([EMAIL_VIEW])
    } else if (view === DynamicView.WALLET_VIEW) {
      setViewOverrides([WALLET_VIEW])
    } else {
      setViewOverrides([])
    }
  }, [view])

  return (
    <DynamicContextProvider
      theme="dark"
      settings={{
        recommendedWallets: [{ walletKey: 'okxwallet' }],
        overrides: {
          views: viewOverrides,
          evmNetworks: (networks) =>
            mergeNetworks(networks, [
              {
                name: 'Metis',
                rpcUrls: [
                  'https://andromeda.metis.io/?owner=1088',
                  'https://lb.nodies.app/v1/f5c5ecde09414b3384842a8740a8c998',
                ],
                nativeCurrency: {
                  name: 'Metis',
                  symbol: 'METIS',
                  decimals: 18,
                },
                iconUrls: ['https://bridge.metis.io/logo.svg'],
                networkId: 1,
                chainId: 1088,
                blockExplorerUrls: ['https://andromeda-explorer.metis.io/'],
              },
              {
                name: 'Manta Pacific Mainnet',
                rpcUrls: [
                  'https://pacific-rpc.manta.network/http',
                  'https://manta-pacific.drpc.org',
                  'https://www.tencentcloud-rpc.com/v2/manta/manta-rpc',
                  'https://r1.pacific.manta.systems/http',
                  'https://manta.nirvanalabs.xyz/mantapublic',
                ],
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                iconUrls: ['https://docs.manta.network/img/favicon.ico'],
                networkId: 1,
                chainId: 169,
                blockExplorerUrls: ['https://manta.socialscan.io/'],
              },
              {
                name: 'Mint Mainnet',
                rpcUrls: ['https://rpc.mintchain.io'],
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                chainId: 185,
                networkId: 1,
                blockExplorerUrls: ['https://explorer.mintchain.io'],
                iconUrls: ['https://www.mintchain.io/favicon.ico'],
              },
              {
                name: 'Mint Testnet',
                rpcUrls: ['https://sepolia-testnet-rpc.mintchain.io'],
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                chainId: 1687,
                networkId: 1,
                blockExplorerUrls: ['https://sepolia-testnet-explorer.mintchain.io'],
                iconUrls: ['https://www.mintchain.io/favicon.ico'],
              },
            ]),
        },
        environmentId: VITE_DYNAMIC_ENVIRONMENT_ID,
        walletConnectors: [EthereumWalletConnectors],
        shadowDOMEnabled: false,
      }}
    >
      {props.children}
    </DynamicContextProvider>
  )
}
