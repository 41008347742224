import request from '.'

import { UserInfo } from '@/api/user.api'

interface WalletConenctParams {
  address: string
  signature: string
  message: string
  nonce?: string
  wallet_name: string
}

// interface WalletConenctParams {
//   address: string,
//   signature: string,
//   message: string,
//   wallet_name: string
// }

class AccountApi {
  async getNonce() {
    const { data } = await request.get<string>('/wallet/sign/nonce')
    return data
  }

  async tonLogin(channel: string, inviter_code: string, params: TonConnectParams, source?: string) {
    const { data } = await request.post<{ token: string; user_info: UserInfo }>('/user/login', {
      connector: 'ton',
      channel,
      inviter_code,
      connect_info: { ton: params },
      sign_up_source: source,
    })
    return data
  }

  async dynamicLogin(channel: string, inviter_code: string, token: string, source?: string) {
    const { data } = await request.post<{ token: string; user_info: UserInfo }>('/user/login', {
      connector: 'dynamic',
      channel,
      inviter_code,
      connect_info: { dynamic: token },
      sign_up_source: source,
    })
    return data
  }

  async walletLogin(channel: string, inviter_code: string, params: WalletConenctParams, source?: string) {
    const { data } = await request.post<{ token: string; user_info: UserInfo }>('/user/login', {
      connector: 'wallet',
      channel,
      inviter_code,
      connect_info: { wallet: params },
      sign_up_source: source,
    })
    return data
  }

  async linkTonWallet(params: TonConnectParams) {
    const { data } = await request.post('/user/account/bind', {
      connector: 'ton',
      connect_info: { ton: params },
    })
    return data
  }

  async linkDynamic(token: string) {
    const { data } = await request.post('/user/account/bind', {
      connector: 'dynamic',
      connect_info: { dynamic: token },
    })
    return data
  }

  async linkWalletAddress(params: WalletConenctParams) {
    const { data } = await request.post('/user/account/bind', {
      connector: 'wallet',
      connect_info: {
        wallet: params,
      },
    })
    return data
  }

  async unlinkAccount(account: string) {
    const { data } = await request.post('/user/account/unbind', {
      account_id: account,
    })
    return data
  }

  async getSocialAccountLinkUrl(type: string) {
    const { data } = await request.post('/user/sm/connect', { type })
    return data
  }

  async unlinkSocialAccount(type: string) {
    const { data } = await request.post('/user/sm/unbind', { type })
    return data
  }

  async linkSocialAccount(type: string, param: any) {
    const { data } = await request.post('/user/sm/bind', {
      type,
      value: param,
    })
    return data
  }

  async getEmailCaptchaCode() {
    const { data } = await request.post('/user/get_image_code')
    return data
  }

  async sendEmailCode(email: string, captcha: string) {
    const { data } = await request.post('/user/get_code', {
      email,
      image_code: captcha,
    })
  }

  async emailLogin(channel, inviter_code, params, source) {
    const { data } = await request.post<{ token: string; user_info: UserInfo }>('/user/login', {
      connector: 'email',
      channel,
      inviter_code,
      connect_info: params,
      sign_up_source: source,
    })
    return data
  }
}

export interface LoginParams {
  connector: string
  channel: string
  inviter_code: string
  connect_info: {
    ton?: TonConnectParams
    dynamic?: string
  }
}

export interface TonConnectParams {
  wallet_name: string
  account: {
    address: string
    chain: number
    walletStateInit: string
    publicKey: string
  }
  ton_proof: {
    domain_len: number
    domain_val: string
    payload: string
    signature: string
    timestamp: number
  }
}

const accountApi = new AccountApi()
export default accountApi
