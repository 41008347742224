import { logout } from '@/store/auth.store'
import { userStoreActions } from '@/store/user.store'
import { LoaderCircle } from 'lucide-react'
import { useEffect, useState } from 'react'

function Loading() {
  return (
    <div className="grid h-screen w-screen place-content-center">
      <LoaderCircle className="animate-spin" size={30} />
    </div>
  )
}

export default function UserDetailLoader(props: { children: React.ReactNode }) {
  const [ready, setReady] = useState(false)

  async function getUserDetail() {
    try {
      await userStoreActions.getUserDetail()
      setReady(true)
    } catch (err: any) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    getUserDetail()
  }, [])

  if (!ready) return <Loading></Loading>
  else return <>{props.children}</>
}
