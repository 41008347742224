import { Route, createBrowserRouter, createRoutesFromElements, defer, redirect } from 'react-router-dom'

import AppLayout from '@/layouts/app-layout'
import SettingsLayout from '@/layouts/settings.layout'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { lockLoader } from './components/lock/lock.loader'
import { FunctionName } from './store/lock.store'
import FullScreenAuthLayout from '@/layouts/full-screen-auth-layout'
import { userStore, userStoreActions } from './store/user.store'

async function userDetailLoader() {
  return defer({ userDetail: userStoreActions.getUserDetail() })
}

const config = createRoutesFromElements(
  <>
    <Route path="" loader={() => redirect('/app')} />
    <Route path="/app/quest" element={<FullScreenAuthLayout />} loader={userDetailLoader}>
      <Route path=":id/challenge" lazy={() => import('@/views/app/quest/quest-challenge')}></Route>
    </Route>
    <Route path="/app/data/profile/:network/:address" lazy={() => import('@/views/single/data-profile/index')} />
    <Route path="/data-profile/:network/:address" lazy={() => import('@/views/single/data-profile/index')} />

    <Route path="/app/nft" element={<FullScreenAuthLayout />} loader={userDetailLoader}>
      <Route path="codatta-genesis-pass" lazy={() => import('@/views/app/nft/codatta-genesis-pass')} />
      {/* <Route path="test" lazy={() => import('@/views/app/nft/nft-test')} /> */}
    </Route>

    <Route path="/app/bounty/:id" element={<FullScreenAuthLayout />} loader={userDetailLoader}>
      <Route path="detail" lazy={() => import('@/views/app/bounty/bounty-detail')}></Route>
      <Route path="submit" lazy={() => import('@/views/app/bounty/bounty-submit')}></Route>
    </Route>
    <Route path="/app/home" lazy={() => import('@/views/app/home')} />
    <Route path="/app" element={<AppLayout />}>
      <Route path="" lazy={() => import('@/views/app/home')} />
      <Route path="submission" loader={lockLoader(FunctionName.Submission)}>
        <Route path="" lazy={() => import('@/views/app/submission/submission-home')} />
        <Route path="history" lazy={() => import('@/views/app/submission/submission-history')} />
        <Route path="submit" lazy={() => import('@/views/app/submission/submission-submit')} />
      </Route>
      <Route path="validation">
        <Route path="" lazy={() => import('@/views/app/validations/overview')} />
        <Route path="filter" lazy={() => import('@/views/app/validations/filter-list')} />
      </Route>
      <Route path="reputation" lazy={() => import('@/views/app/reputation')} />

      <Route path="bounty" loader={lockLoader(FunctionName.BountyHunting)}>
        <Route path="" lazy={() => import('@/views/app/bounty/bounty-hunting')} />
        <Route path="list" lazy={() => import('@/views/app/bounty/bounty-list')} />
      </Route>

      <Route path="quest">
        <Route path="" lazy={() => import('@/views/app/quest/activity-group')} />
        <Route path=":categoryId" lazy={() => import('@/views/app/quest/activity')} />
      </Route>
      <Route path="ecosystem" lazy={() => import('@/views/app/ecosystem')} />
      <Route path="explorer" lazy={() => import('@/views/app/explorer')} />
      <Route path="referral" lazy={() => import('@/views/app/referral')} />
      <Route path="notification" lazy={() => import('@/views/app/notification')} />

      <Route path="" loader={() => redirect('/app/settings/account')} />
      <Route path="settings" element={<SettingsLayout />}>
        <Route path="" loader={() => redirect('./account')} />
        <Route path="account" lazy={() => import('@/views/app/settings/account')}></Route>
        <Route path="notification" lazy={() => import('@/views/app/settings/notification')}></Route>
      </Route>
    </Route>
    {/* <Route path="/account/signin" lazy={() => import('@/views/account/signin')} /> */}
    <Route path="/account/extension/signin" lazy={() => import('@/views/account/extension-signin')} />
    <Route path="/account/signin" lazy={() => import('@/views/account/signin-v2')} />
    <Route path="/account/active/invite" lazy={() => import('@/views/account/active-invite')} />
    <Route path="/account/social/link/:social_media" lazy={() => import('@/views/account/social-link-landing')}></Route>
    <Route path="referral/:code" lazy={() => import('@/views/referral-landing')} />
    <Route path="*" lazy={() => import('@/views/errors/not-found')} />
  </>,
)

export const router = wrapCreateBrowserRouter(createBrowserRouter)(config)
