import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg width={size} height={(size * 25) / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99988 21.415H16.9999M7.86 18.441H16.1399C17.2439 18.441 18.2172 17.7168 18.5344 16.6594L20.7021 9.4339C20.8223 9.03295 20.4261 8.67052 20.0374 8.82599L16.7817 10.1283C16.3299 10.309 15.8137 10.1383 15.5586 9.72391L12.4258 4.63297C12.2305 4.3157 11.7693 4.3157 11.5741 4.63297L8.44121 9.72391C8.1862 10.1383 7.66993 10.309 7.21816 10.1283L3.96241 8.82599C3.57374 8.67052 3.17751 9.03295 3.2978 9.4339L5.46544 16.6594C5.78268 17.7168 6.75598 18.441 7.86 18.441Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}
