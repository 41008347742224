import { tourStore } from '@/stores/tour.store'

export const TourMock = (mockData: (step: number) => any) => {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const fn = descriptor.value
    descriptor.value = async function (...rest) {
      const { current } = tourStore
      const params = new URLSearchParams(location.search)
      const isTourMode = params.get('mode') === 'tour'
      if (isTourMode) {
        return Promise.resolve(mockData(current))
      } else {
        const result = await fn.apply(this, rest)
        return result
      }
    }
  }
}
