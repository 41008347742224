import { isNil } from 'lodash'
import { proxy, snapshot } from 'valtio'

export const lock = proxy({
  unlockedFunctions: null as FunctionName[],
})

export const isLocked = (functionName: FunctionName) => {
  const isFunctionNameExcluded = !snapshot(lock).unlockedFunctions?.includes(functionName)
  return isNil(isFunctionNameExcluded) ? true : isFunctionNameExcluded
}

export enum FunctionName {
  Submission = 'SUBMISSION',
  BountyHunting = 'BOUNTY_HUNTING',
}
