import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg width={size} height={(size * 25) / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5151 3.90503C8.95654 3.94387 7.34428 4.04182 5.52188 4.19884C4.34668 4.3001 3.40824 5.22563 3.29952 6.4004C2.85403 11.2142 2.94048 14.5882 3.32895 19.3461C3.4267 20.5433 4.37273 21.4995 5.56934 21.6015C10.3142 22.0061 13.6644 22.007 18.4706 21.5995C19.6643 21.4983 20.6104 20.5473 20.7092 19.3531C20.8584 17.5494 20.9572 15.9486 21 14.4006"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2888 3.90502C14.2888 3.57365 14.5574 3.30502 14.8888 3.30502H20.7999C21.2417 3.30502 21.5999 3.6632 21.5999 4.10502V10.0161C21.5999 10.3475 21.3313 10.6161 20.9999 10.6161C20.6685 10.6161 20.3999 10.3475 20.3999 10.0161V5.35355L11.4242 14.3293C11.1899 14.5636 10.81 14.5636 10.5756 14.3293C10.3413 14.095 10.3413 13.7151 10.5756 13.4808L19.5514 4.50502H14.8888C14.5574 4.50502 14.2888 4.23639 14.2888 3.90502Z"
        fill={color}
      />
    </svg>
  )
}
