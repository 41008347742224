import { ConfigProvider } from 'antd'
import { LoaderCircle } from 'lucide-react'
import { Suspense } from 'react'
import { Await, Outlet, useLoaderData } from 'react-router-dom'
import * as Sentry from '@sentry/react'

function Loading() {
  return (
    <div className="grid h-screen w-screen place-content-center">
      <LoaderCircle className="animate-spin" />
    </div>
  )
}

export default function FullScreenAuthLayout() {
  const loaderData: any = useLoaderData()

  return (
    <ConfigProvider>
      <Sentry.ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Await resolve={loaderData.userDetail}>
            <Outlet />
          </Await>
        </Suspense>
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  )
}
