export function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('uid')

  // clear dynamic login status
  localStorage.removeItem('codatta_auth_store')

  const url = new URL(window.location.href)
  const from = url.pathname + url.search
  window.location.href = `/account/signin?from=${encodeURIComponent(from)}`
}

export function checkLogin() {
  const token = localStorage.getItem('token')
  const uid = localStorage.getItem('uid')
  return !!token && !!uid
}
