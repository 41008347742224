import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

export function useReactGA() {
  const { pathname, search } = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: pathname + search })
  }, [pathname, search])
}
