import request from '.'

class TourApi {
  async getStatus() {
    const { data } = await request.get<Record<TourType, TourState>>('/user/guide/info')

    return {
      ...data,
      ...JSON.parse(localStorage.getItem('tour_status') ?? '{}'),
    }
  }

  async updateStatus(type: TourType, status: TourState) {
    const data = await request.post<{ success: boolean; error: string }>('/user/guide/save', {
      guide_type: type,
      status,
    })
    // @ts-expect-error success
    return data.success
  }
}

const tourApi = new TourApi()
export default tourApi

export enum TourState {
  Study = 'study',
  Coming = 'coming',
  Finish = 'finish',
  Skip = 'skip',
}

// ! 必须使用 `_` 表示子类型 前缀为父类型
export enum TourType {
  Submission = 'submission',
  Validation = 'validation',
  Hunting = 'bounty_hunting',
  Reputation = 'reputation',
  ValidationDetail = 'validation_detail',
  ValidationDetailNew = 'validation_detail_new',
  ValidationFilter = 'validation_filter',
  ValidationFilterNew = 'validation_filter_new',
  ValidationList = 'validation_list',
  ValidationListNew = 'validation_list_new',
  SubmissionHome = 'submission_home',
  SubmissionForm = 'submission_form',
  SubmissionList = 'submission_list',
}
