import { useReactGA } from '@/hooks/useReactGA'
import { LoaderCircle } from 'lucide-react'
import { Suspense, useEffect, useState } from 'react'
import { Await, Outlet, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom'

import { tourStore } from '@/stores/tour.store'
import { ConfigProvider, Layout } from 'antd'
import { useSnapshot } from 'valtio'

import TourBar from '@/components/tour/tour-bar'
import SideNav from '@/layouts/app-sider'

import * as Sentry from '@sentry/react'
import SentryErrorFallback from './sentry-error-fallback'
import { notificationStoreActions } from '@/stores/notification.store'
import { checkLogin } from '@/store/auth.store'
import UserDetailLoader from '@/components/common/user-detail-loader'

export default function AppLayout() {
  useReactGA()
  const [params] = useSearchParams()
  const navigate = useNavigate()

  // TODO optimize
  useEffect(() => {
    if (params.get('mode') === 'tour') {
      if (tourStore.activeName) {
        tourStore.isTourMode = true
      }
    } else {
      tourStore.isTourMode = false
    }
  }, [params])

  useEffect(() => {
    const result = checkLogin()
    if (!result) {
      navigate('/account/signin')
      return
    }

    notificationStoreActions.getUnread()
  }, [])

  const { isTourMode } = useSnapshot(tourStore)

  return (
    <Sentry.ErrorBoundary fallback={SentryErrorFallback}>
      <ConfigProvider theme={{ token: { motion: !isTourMode } }}>
        <UserDetailLoader>
          <Layout className="mx-auto h-screen max-w-[1920px]">
            <Layout.Header className="z-1002 h-auto bg-transparent p-0 empty:hidden">
              <TourBar />
            </Layout.Header>
            <Layout>
              <Layout.Sider style={{ backgroundColor: 'transparent' }} breakpoint="lg" width={247}>
                <SideNav />
              </Layout.Sider>
              <Layout.Content className="min-w-0 flex-1 overflow-y-scroll p-0">
                <Outlet />
              </Layout.Content>
            </Layout>
          </Layout>
        </UserDetailLoader>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
  )
}
