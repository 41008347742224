import ImageLogo from '@/assets/images/logo-white.png'

import { useUserStore } from '@/store/user.store'
import { useRef } from 'react'
import AppNav from './app-nav'
import AppUser from './app-user'

function LogoSection() {
  const timer = useRef(null)
  const { info } = useUserStore()
  function handleMouseEnter() {
    timer.current = setTimeout(() => {
      alert(`Welcome!, ${info?.user_id}`)
    }, 5000)
  }

  function handleMouseLeave() {
    clearTimeout(timer.current)
  }

  return (
    <div
      className="mb-8 hidden items-center pl-8 pt-2 lg:flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={ImageLogo} className="h-6" />
    </div>
  )
}

export default function AppSider() {
  return (
    <div className="flex h-full w-full flex-col py-6">
      <LogoSection />
      <AppNav className="flex-1" />
      <AppUser />
    </div>
  )
}
