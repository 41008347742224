import { Button } from 'antd'
import { Bug } from 'lucide-react'

export default function SentryErrorFallback({ error, componentStack, resetError }) {
  function handleBack() {
    window.history.back()
    setTimeout(() => {
      resetError()
    }, 100)
  }

  function handleTryAgain() {
    window.location.reload()
  }

  return (
    <div role="alert" className="flex h-full min-h-screen flex-col items-center justify-center">
      <h1 className="text-24px gap-12px mb-16px block flex items-center">
        <Bug size={60}></Bug>
        Opps! Something went wrong.
      </h1>
      <pre className="mb-24px">{error.message}</pre>
      <div className="gap-12px mt-8px flex">
        <Button onClick={handleBack}>Back</Button>
        <Button onClick={handleTryAgain} type="primary">
          Try again
        </Button>
      </div>
    </div>
  )
}
