import { IconProps } from './icon.types'

export default function Icon(props: IconProps) {
  const color = props.color || 'currentColor'
  //   const strockWidth = props.strokeWidth || 1.66667
  const size = props.size || 24

  return (
    <svg width={size} height={(size * 25) / 24} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9997 21.905C16.5184 21.905 20.1816 18.2419 20.1816 13.7232C20.1816 9.2045 16.5184 5.54135 11.9997 5.54135C7.481 5.54135 3.81787 9.2045 3.81787 13.7232C3.81787 18.2419 7.481 21.905 11.9997 21.905Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68687 3.5563C6.87948 3.82594 6.81702 4.20067 6.54738 4.39328L3.4234 6.62469C3.15375 6.8173 2.77902 6.75484 2.58642 6.48519C2.39381 6.21555 2.45627 5.84082 2.72591 5.64821L5.84989 3.4168C6.11954 3.22419 6.49427 3.28665 6.68687 3.5563ZM17.3137 3.5563C17.5063 3.28665 17.8811 3.22419 18.1507 3.4168L21.2747 5.64821C21.5443 5.84082 21.6068 6.21555 21.4142 6.48519C21.2216 6.75484 20.8469 6.8173 20.5772 6.62469L17.4532 4.39328C17.1836 4.20067 17.1211 3.82594 17.3137 3.5563ZM11.8928 8.37194C12.2242 8.37198 12.4928 8.64064 12.4928 8.97201L12.4922 13.6365L15.7851 16.9294C16.0194 17.1637 16.0194 17.5436 15.7851 17.7779C15.5508 18.0122 15.1709 18.0122 14.9366 17.7779L11.2922 14.1335L11.2928 8.97188C11.2928 8.6405 11.5615 8.3719 11.8928 8.37194Z"
        fill={color}
      />
    </svg>
  )
}
