import { lock } from '@/store/lock.store'
import { bountyStore } from '@/store/bounty.store'
import request from '.'
import { AxiosResponse } from 'axios'

class NotificationApi {
  async getNotificationList(
    page: number,
    pageSize: number,
    type?: string,
  ): Promise<B18A.PaginationResponse<B18A.NotificationItem[]>> {
    const data = await request.post<B18A.PaginationResponse<B18A.NotificationItem[]>>('/msg/entry', {
      page_num: page,
      page_size: pageSize,
      notify_type: type,
    })
    return data as any
  }

  async getNotificationProfile(): Promise<B18A.Response<B18A.NotificationProfile>> {
    const data = await request.post('/msg/profile')
    return data as any
  }

  async updateNotificationProfile(data: B18A.NotificationProfile) {
    const res = await request.post('/msg/edit', data)
    return res as any
  }

  async getNotificationUnread(): Promise<
    B18A.Response<{
      finished_task_count: number
      msg_unread_count: number
      unlocked_functions: string[]
      bounty_has_new: number
    }>
  > {
    const res = await request.post('/msg/unread')
    lock.unlockedFunctions = res.data.unlocked_functions ?? []
    bountyStore.bounty_has_new = res.data.bounty_has_new
    return res as any
  }
}

const notificationApi = new NotificationApi()
export default notificationApi
