import { tourStore } from '@/stores/tour.store'
import { TourContext } from '@/tour/context'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext } from 'react'
import { useSnapshot } from 'valtio'

const Component = (props) => {
  const { isTourMode } = useSnapshot(tourStore)
  const newTour = useContext(TourContext)
  if (isTourMode || newTour?.isTourMode) return <div className={props.className}>{props.children}</div>

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: -30, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 30, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={props.className}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}

export default Component
